<template>
<div class="bgc-white p-20 bd" id="mainContent">
    
    <div class="row mB-30">
      <div class="col-sm-4"><h4 class="c-grey-900">التقارير<span class="c-green-900">({{ "المتصلون الآن: "+data.clients }})</span></h4></div>
      <div class="col-sm-1"><label for="day">يوم</label><input type="radio" id="day" value="1" v-model="duration" @change="getReport"></div>
      <div class="col-sm-1"><label for="month">شهر</label><input type="radio" id="month" value="2" v-model="duration" @change="getReport"></div>
      <div class="col-sm-1"><label for="year">سنة</label><input type="radio" id="year" value="3" v-model="duration" @change="getReport"></div>
    </div>

              <loading v-show="loading" />

        <div v-show="!loading" class="row gap-20">
      <div class="col-md-3">
        <div class="layers bd bgc-white p-20">
          <div class="layer w-100 mB-10">
            <h6 class="lh-1">الدفعات</h6>
          </div>
          <div class="layer w-100">
            <div class="peers ai-sb fxw-nw">
              <div class="peer peer-greed">
                <span id="sparklinedash">
                  <canvas style="display: inline-block; width: 45px; height: 20px; vertical-align: top;" width="45" height="20"></canvas>
                </span>
              </div>
              <div class="peer">
                <span class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-green-50 c-green-500">{{data.payments +' '+' دفعة' + ' -> '+data.payments_amount +'جنيه' }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="layers bd bgc-white p-20">
          <div class="layer w-100 mB-10">
            <h6 class="lh-1">الطلاب</h6>
          </div>
          <div class="layer w-100">
            <div class="peers ai-sb fxw-nw">
              <div class="peer peer-greed">
                <span id="sparklinedash2">
                  <canvas style="display: inline-block; width: 45px; height: 20px; vertical-align: top;" width="45" height="20"></canvas>
                </span>
              </div>
              <div class="peer">
                <span class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-purple-50 c-purple-500">{{data.students+' طالب'+' -> '+data.students_in_duration+' طالب'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="layers bd bgc-white p-20">
          <div class="layer w-100 mB-10">
            <h6 class="lh-1">الإمتحانات</h6>
          </div>
          <div class="layer w-100">
            <div class="peers ai-sb fxw-nw">
              <div class="peer peer-greed">
                <span id="sparklinedash3">
                  <canvas style="display: inline-block; width: 45px; height: 20px; vertical-align: top;" width="45" height="20"></canvas>
                </span>
              </div>
              <div class="peer">
                <span class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-blue-50 c-blue-500">{{data.exams +' إمتحان' +' || '+data.watched_videos+' مشاهدة فديو' +' || '+data.ludo+' مبارة'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="layers bd bgc-white p-20">
          <div class="layer w-100 mB-10">
            <h6 class="lh-1">التكاليف</h6>
          </div>
          <div class="layer w-100">
            <div class="peers ai-sb fxw-nw">
              <div class="peer peer-greed">
                <span id="sparklinedash4">
                  <canvas style="display: inline-block; width: 45px; height: 20px; vertical-align: top;" width="45" height="20"></canvas>
                </span>
              </div>
              <div class="peer">
                <span class="d-ib lh-0 va-m fw-600 bdrs-10em pX-15 pY-15 bgc-red-50 c-red-500">{{data.costs+' جنيه'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="!loading" class="row gap-20 mT-40">
        <div class="masonry-item col-md-12">
            <div class="bd bgc-white">
  <div class="layers">
    <div class="layer w-100">
      <div class="bgc-blue-500 c-white p-20">
        <div class="peers ai-c jc-sb gap-40">
          <div class="peer peer-greed">
            <h5>تقرير الصفوف</h5>
            <p class="mB-0">تقرير شامل عن كل صف</p>
          </div>
        </div>
      </div>
      <div class="table-responsive p-20">
        <table class="table">
          <thead>
            <tr>
              <th class="bdwT-0">الصف</th>
              <th class="bdwT-0">إجمالي الامتحانات</th>
              <th class="bdwT-0">إجمالي مشاهدات الفديو</th>
              <th class="bdwT-0">إجمالي مباريات الليدو</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in data.classes" :key="item.id">
              <td>{{item.level.ar_name+' - '+item.ar_name}}</td>
              <td>{{ item.exams_count +' امتحان' }}</td>
              <td>{{ item.watched_videos_count +' مشاهدة فديو' }}</td>
              <td>{{ item.ludo_count+' مبارة'}}</td>
            </tr>
            
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
        </div>

    </div>

    <div v-show="!loading" class="row gap-20 mT-40">
        <div class="masonry-item col-md-12">
            <div class="bd bgc-white">
  <div class="layers">
    <div class="layer w-100">
      <div class="bgc-blue-500 c-white p-20">
        <div class="peers ai-c jc-sb gap-40">
          <div class="peer peer-greed">
            <h5>تقرير الطلاب</h5>
            <p class="mB-0">تقرير شامل عن كل الطلاب</p>
          </div>
        </div>
      </div>
      <div class="table-responsive p-20">
        <table class="table">
          <thead>
            <tr>
              <th class="bdwT-0">النشاط</th>
              <th class="bdwT-0">عدد الطلاب</th>
            </tr>
          </thead>
          <tbody>
            <tr>
             <td>الامتحان التفاعلي</td>
             <td @click="showPopup(data.students_make_an_exam)"><a href="javascript:" >{{ (data.students_make_an_exam? data.students_make_an_exam.length : '0') +' طالب' }}</a></td>
            </tr>
            <tr>
             <td>ليدو الأسئلة</td>
             <td @click="showPopup(data.students_play_ludo)"><a href="javascript:" >{{ (data.students_play_ludo? data.students_play_ludo.length : '0') +' طالب' }}</a></td>
            </tr>
            <tr>
             <td>مشاهدة فديو شرح</td>
             <td @click="showPopup(data.students_watch_video)"><a href="javascript:" >{{ (data.students_watch_video? data.students_watch_video.length : '0') +' طالب' }}</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
        </div>

    </div>


    <div id='popup' class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <table class="table">
          <thead>
            <tr>
              <th class="bdwT-0">اسم الطالب</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in students" :key="item.id">
             <td>{{ (index + 1)+'. '+item.name }}</td>
            </tr>
            
          </tbody>
        </table>
    </div>
  </div>
</div>
</div>
</template>

<script>
export default {
    data() {
       return {
        data:{},
        loading: false,
        duration: 2,
        students:[]
       };
    },
    created() {
        this.getReport();
    },
    methods: {
      showPopup(students) {
        this.students = students;
        $('#popup').modal('show');
      },
        getReport: function () {
      this.loading = true;
      this.$http
        .get("https://yalla-emtihan.com/backend/supervisors/getReports", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
            duration : this.duration
          },
        })
        .then(
          function (data) {
            this.loading = false;
            switch (data.status) {
              case 200:
                this.data = data.body;
                break;
            }
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          }
        );
    },
    }

}
</script>

<style lang="css" scoped>
#mainContent {
  text-align: right;
  direction: rtl;
}
</style>